<template>
  <div>
    <v-chip
        class="ma-2"
        :color="getColor(val)"
        text-color="white"
        >
        {{ val }}
    </v-chip>
  </div>
</template>
<script>
export default {
  props: {
    val: {
      type: String,
    },
  },
  methods: {
    getColor(val) {
        if (val == 'Paid') {
        return 'green'
        } else if (val == 'Partially Paid') {
        return 'warning'
        } else if (val == 'Unpaid') {
        return 'error'
        }
    }
  },
};
</script>
