<template>
  <div>
    <component-layout title="Invoices">
      <template #menu>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <router-link
              style="color: #000"
              class="text-decoration-none"
              :to="{ path: '/invoice/create' }"
            >
              <v-list-item v-ripple="{ center: true }">
                ADD
              </v-list-item></router-link
            >
          </v-list>
        </v-menu>
      </template>

      <vue-table
        :key="key"
        itemName="invoice"
        :url="table.url"
        :except="table.except"
        :sn="false"
        :headingTransformer="table.headingTransformer"
        :html="table.html"
        :additionalColumns="table.additionalColumns"
        :additionalColumnsTransformer="table.additionalColumnsTransformer"
        :valueTransformer="table.valueTransformer"
        :columnSortOrder="table.columnSortOrder"
      ></vue-table>
      <!-- </div>
      </div> -->
    </component-layout>
    <v-dialog v-model="showAssignJobModal" max-width="450px">
      <assign-job :invoice_id="this.invoice_id" />
    </v-dialog>
  </div>
</template>
<script>
import util from "@/util/util";
import VueTable from "@/components/shared/vue-table.vue";
import action from "@/components/shared/action-quotation.vue";
import ComponentLayout from "@/components/component-layout.vue";
import payStatus from "@/components/Invoice/partials/pay-status.vue";
import jobNo from "@/components/Invoice/partials/job-no.vue";
import AssignJob from "./assign-job.vue";
export default {
  components: {
    VueTable,
    ComponentLayout,
    AssignJob,
  },
  data() {
    return {
      key: 0,
      quotations: [],
      selectedQuotation: {},
      showAssignJobModal: false,
      invoice_id: null,
      page: 0,
      last_page: 0,
      searchTerm: "",
      table: {
        url: "invoice",
        except: [
          "id",
          "created_at",
          "updated_at",
          "deleted_at",
          "discount",
          "valid_till",
          "prepared_by",
          "due_amount",
          "discount_formatted",
          "subTotal_formatted",
          "taxTotal_formatted",
          "payments",
          "paid_status",
          "notes",
          "customer_id",
          "otp",
          "template",
          "template_id",
          "products",
          "files",
          "tax",
          "terms",
          "po_id",
          "subTotal",
          "taxTotal",
          "paid_amount_formatted",
          "paid_amount",
          "total",
          "issue_date",
          "status",
          "job",
          "job_id",
        ],
        columnSortOrder: {
          ref_no: 1,
          job_no: 2,
          customer: 3,
          discount: 4,
          total: 5,
          paid_amount: 6,
          due_amount: 7,
          issue_date: 8,
          valid_till: 9,
          voic: 10,
          status: 11,
          Action: 12,
        },
        html: [
          "Action",
          "customer",
          "pay_status",
          "job_no",
          // "issue_date",
          // "valid_till",
          // "status",
          // "total",
          // "discount",
          // "due_amount",
          // "paid_amount",
        ],
        additionalColumns: ["Action", "job_no"],
        headingTransformer: function(val) {
          if (val == "valid_till_formatted") {
            return "Due Date";
          }
          if (val == "total_formatted") {
            return "Total Amount";
          }
          if (val == "issue_date_formatted") {
            return "Issue Date";
          }
          if (val == "due_amount_formatted") {
            return "Due Amount";
          }
          return util.camelCase(val);
        },
        additionalColumnsTransformer: function() {
          return {
            Action: (row) => {
              return [
                {
                  comp: action,
                  prop: {
                    data: row,
                    name: "invoice",
                  },
                },
              ];
            },
            job_no: (row) => {
              return [
                {
                  comp: jobNo,
                  prop: {
                    job: row.job,
                  },
                },
              ];
            },
          };
        },
        valueTransformer: function() {
          return {
            customer: (row) => {
              return [
                {
                  item: row.customer.full_name,
                  handler: () => null,
                },
              ];
            },
            // issue_date: (row) => {
            //   return [
            //     {
            //       item: row.issue_date_formatted,
            //       handler: () => null,
            //     },
            //   ];
            // },
            // valid_till: (row) => {
            //   return [
            //     {
            //       item: row.valid_till_formatted,
            //       handler: () => null,
            //     },
            //   ];
            // },
            // total: (row) => {
            //   return [
            //     {
            //       item: row.total_formatted,
            //       handler: () => null,
            //     },
            //   ];
            // },
            // discount: (row) => {
            //   return [
            //     {
            //       item: row.discount_formatted,
            //       handler: () => null,
            //     },
            //   ];
            // },
            // due_amount: (row) => {
            //   return [
            //     {
            //       item: row.due_amount_formatted,
            //       handler: () => null,
            //     },
            //   ];
            // },
            // paid_amount: (row) => {
            //   return [
            //     {
            //       item: row.paid_amount_formatted,
            //       handler: () => null,
            //     },
            //   ];
            // },
            // status: (row) => {
            //   return [
            //     {
            //       item:
            //         '<span style="color:white;padding:0.4rem" class="badge ' +
            //         (row.status == 0 ? "new badge orange" : "new badge green") +
            //         '">' +
            //         (row.status == 0 ? "Draft" : "Published") +
            //         "</span>",
            //       handler: () => null,
            //     },
            //   ];
            // },
            pay_status: (row) => {
              return [
                {
                  comp: payStatus,
                  prop: {
                    val: row.paid_status,
                  },
                },
              ];
            },
          };
        },
      },
    };
  },
  methods: {
    itemEdit(id) {
      this.$router.push(`/invoice/${id}/edit`);
    },
    itemDetail(id) {
      this.$router.push(`/invoice/${id}/detail`);
      // console.log(id);
    },
    assignJob(id) {
      this.showAssignJobModal = true;
      this.invoice_id = id;
    },
  },
  mounted() {
    util.event.$on("item-selected", (item) => {
      this.selectedQuotation = item;
    });
    util.event.$on("item-edit", (id) => {
      this.itemEdit(id);
    });

    util.event.$on("item-detail", (id) => {
      this.itemDetail(id);
    });
    util.event.$on("job-assign", (id) => {
      this.assignJob(id);
    });
    util.event.$on("reload-items", () => {
      this.key++;
    });
  },
};
</script>
