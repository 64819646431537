<template>
  <div class="px-5 py-5">
    <component-layout :title="'Assign Job'">
      <v-text-field
        label="Job Number"
        placeholder=""
        v-bind="basic"
        v-model="job_no"
        :rules="[util.validation.required]"
      ></v-text-field>
      <div class="row justify-space-between mt-10">
        <v-btn class="submit-btn" @click="assign" :loading="loading"
          >Assign
        </v-btn>
        <v-btn class="cancel-btn">Cancel </v-btn>
      </div>
    </component-layout>
  </div>
</template>

<script>
import util from "@/util/util";
import componentLayout from "../component-layout.vue";
export default {
  props: ["invoice_id"],
  components: { componentLayout },
  data() {
    return {
      util,
      job_no: "",
      basic: util.input.basic,
      loading: false,
      invoice: {},
    };
  },
  mounted() {
    this.getInvoice();
  },
  methods: {
    getInvoice() {
      util
        .http({
          url: "/invoice/" + this.$props.invoice_id,
          method: "GET",
        })
        .then((res) => {
        //   this.invoice = res.data.data;
        //   this.job_no = res.data.data.data.job.job_no
          console.log(res.data.data);
        });
    },
    assign() {
      this.loading = true;
      util
        .http({
          url: "/invoice/assign-job",
          method: "PUT",
          data: {
            job_no: this.job_no,
            invoice_id: this.$props.invoice_id,
          },
        })
        .then((resp) => {
          this.loading = false;
          console.log(resp);
          if (resp.data.status == 1) {
            util.notify(1, "Asssigned Successfullly");
            // this.$router.push({ name: "jobs.detail", params: { id: resp.data.data.id } });
          } else {
            util.notify(0, resp.data.errors[0]);
          }
        });
      console.log(this.form);
    },
  },
};
</script>
