<template>
    <span @click="jobDetail()" v-if="$props.job" style="cursor: pointer; color:#1976d2">
        {{ $props.job.job_no }}
    </span>
</template>
<script>
export default {
    props: ['job'],
    methods: {
        jobDetail() {
            this.$router.push({
                name: 'jobs.detail',
                params: { id: this.$props.job.id }
            });
        }
    }
}
</script>